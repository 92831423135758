<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <img :src="m_ten + webp()" alt="" class="im-image" />
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
      <div class="flex-fill d-flex">
        <div>
          <div class="im-title">ТЭН DomSpirt 3500 Вт</div>
          <div class="im-subtitle">Автономный нагрев аппарата</div>
          <div class="im-text">
            <p>
              Домашний спиртзавод DomSpirt можно нагревать на любых типах плит,
              в том числе индукционной, или ТЭНом. При этом
              <u>ТЭН не требует какой-либо регулировки и стабилизации</u> -
              преимущества тарельчатой колонны DomSpirt перед насадочными с СПН
              и РПН здесь неоспоримы.
            </p>
            <p>
              ТЭН DomSpirt 3500 Вт идеален для любых самогонных аппаратов.
              Продуманная конструкция “коготь” со смещением вниз обеспечивает
              минимальный среди всех конкурентов заливаемый уровень жидкости в
              ёмкости. Нержавеющая сталь AISI 316Ti с титаном гарантирует
              неограниченный срок службы. Мощность 3500 Вт обеспечивает скорость
              простой перегонки 7 л/час либо ректификации 2 л/час. ТЭН DomSpirt
              может быть оснащён любым регулятором. В комплекте ТЭН, длина
              кабеля - 1 метр.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center im-delivery-container">
        <div class="col-auto">
          <img :src="car" alt="" class="im-delivery-icon" />
        </div>
        <div class="col col-md-8 im-delivery-text">
          Доставка при заказе с аппаратом DOMSPIRT бесплатная.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const m_ten = require("@/pages/Main/assets/modal/m_ten.png");
const car = require("@/pages/Main/assets/car.svg");
import webp from "@/functions/webp";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "html",
  data: function () {
    return {
      webp,
      m_ten,
      car,
    };
  },
};
</script>

<style scoped></style>
